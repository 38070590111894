import React from "react";
export const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
]



export const INLINE_STYLES = [
    {label: <strong>B</strong>, style: 'BOLD'},
    {label: <i>I</i>, style: 'ITALIC'},
    {label: <u>U</u>, style: 'UNDERLINE'},
    {label: "{}", style: 'CODE'},
    {label: <strike>abc</strike>, style: 'STRIKETHROUGH'},
]

export const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    'STRIKETHROUGH': {
        textDecoration: 'line-through',
    },
}

export const getBlockStyle = (block) => {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return 'RichEditor-unstyled';
    }
}