import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {updateObject} from '../../../../utility'

export const actionTypes = {
  SetMenu: "[Set Menu] Action",
  ClearMenu: "[Clear Menu] Action",
};

const initialLayoutState = {
  menu : [{
    title: "Dashboard",
    root: true,
    icon: {
        icon: "flaticon2-architecture-and-city"
    },
    page: "/dashboard",
    translate: "MENU.DASHBOARD",
    bullet: "dot"
  },]
};

export const reducer = persistReducer(
  { storage, key: "v713-layout", whitelist: ["menu"] },
  (state = initialLayoutState, action) => {
    switch (action.type) {
      case actionTypes.ClearMenu: {
        return updateObject(state,
            {
              ...state,
              menu : initialLayoutState.menu,
            }
        )
      }

      case actionTypes.SetMenu: {
        const { menu } = action.payload;
        return updateObject(state,
            {
              ...state,
              menu : menu,
            }
        )
      }

      default:
        return state;
    }
  }
);

export const actions = {
  clearMenu: () => ({ type: actionTypes.ClearMenu }),
  setMenu: (menu) => ({ type: actionTypes.SetMenu, payload: { menu } }),
};