export default {
    timeLeft : (dueDate) => {
        const countDownDate = new Date(dueDate).getTime();
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const response = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
            text: ""
        }

        // If the count down is over, write some text

        if (response.days > 0) {
            response.text = response.days + " d"
        } else if (response.hours > 0) {
            response.text = response.hours + " h"
        } else if (response.minutes > 0) {
            response.text = response.minutes + " m"
        } else if (response.seconds > 0) {
            response.text = response.seconds + " s"
        }
        if (distance < 0) {
            //clearInterval(x);
            response.text = "Due";
        }
        return response
    },
    timePast : (date) => {
        const countDownDate = new Date(date).getTime();
        const now = new Date().getTime();
        const distance = now - countDownDate;

        const response = {
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
            text: ""
        }

        // If the count down is over, write some text

        if (response.days > 0) {
            response.text = response.days + " d"
        } else if (response.hours > 0) {
            response.text = response.hours + " h"
        } else if (response.minutes > 0) {
            response.text = response.minutes + " m"
        } else if (response.seconds > 0) {
            response.text = response.seconds + " s"
        }
        if (distance < 0) {
            //clearInterval(x);
            response.text = "Now";
        }
        return response
    },
    secToString: (val) => { // val : Time in seconds

        const days = Math.floor(val / (1000 * 60 * 60 * 24));
        const hours = Math.floor((val % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((val % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((val % (1000 * 60)) / 1000);

        let value = " ";
        if (days > 0){
            value = value + days + "d ";
        }
        if (hours > 0){
            value = value + hours + "h ";
        }
        if (minutes > 0){
            value = value + minutes + "m ";
        }
        /*if (days+hours+minutes < 0 || seconds > 0){
            value = value + seconds + "s ";
        }*/
        if (val < 0) {
            //clearInterval(x);
            value = "Error"
        }
        return value;

    },

}