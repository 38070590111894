/**
 * High level router-NOTUSED.
 *
 * Note: It's recommended to compose related routes in internal router-NOTUSED
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {lazy} from "react";
import {Redirect, Switch, Route, useRouteMatch} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

const WDashboardpage = lazy(() =>
    import("./pages/home/WDashboard/WDashboard")
);

const PublicPage = lazy(()=> import("./PublicPage"));

export function Routes() {
    let { path, url } = useRouteMatch();
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );


  return (
    <Switch>
        <Route path="/WDashboard" component={WDashboardpage} />

      {!isAuthorized ? (
        /*Render auth-NOTUSED page when user at `/auth-NOTUSED` and not authorized.*/
        <Switch>
            <Route path={["/auth","/auth/login"]}>
              <AuthPage />
            </Route>
            <Route path="/logout" component={Logout} />
            <PublicPage/>
            <Redirect to="/auth" />
        </Switch>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
        <Route path="/logout" component={Logout} />
        <Route path="/error" component={ErrorsPage} />

        <Switch>
            {!isAuthorized ? (
                /*Redirect to `/auth-NOTUSED` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    </Switch>
  );
}
