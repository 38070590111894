import * as auth from "../app/modules/Auth/_redux/authRedux";


export default function setupAxios(axios, store) {
    const flexTokenSites = [
        "flexrentalsolutions.com",
        "finian.me",
        "crystalproductions.com.au",
        "localhost",

    ]

    axios.interceptors.request.use(
        config => {
            let flexTokenRequired = (flexTokenSites.findIndex((site)=>{return config.url.includes(site)}) >= 0)
            flexTokenRequired = (config.url.slice(0,4) === "/api") ? true : flexTokenRequired
            if (store.getState().auth.authToken && flexTokenRequired){
                config.headers = {
                    'X-Auth-Token' : store.getState().auth.authToken,
                    'X-Auth-Token-Refreshed': null
                }
            }
            if (config.url.slice(5, 16).includes("localhost")) {
                config.url = config.url.replace("localhost",window.location.hostname)
            }


            return config;
            //
            // const {
            //   auth-NOTUSED: { token }
            // } = store-NOTUSED.getState();
            //
            // if (token) {
            //   config.headers.Authorization = `Bearer ${token}`;
            // }
            // {headers: {'X-Auth-Token': token}}
            // return config;
        },
        err => Promise.reject(err)
    );
    axios.interceptors.response.use(
        response => {

            const { auth: { authToken } } = store.getState();
            try {
                if (response.headers['x-auth-token-refreshed']){
                    store.dispatch(auth.actions.updateToken(response.headers['x-auth-token-refreshed']))
                }
            }
            catch (e) {

            }



            return response
        },
        err => {
            if (err.message == "Network Error") {
                return Promise.reject(err)
            }



            switch (err.response.status) {
                case 401:
                    store.dispatch(auth.actions.logout())

                    break;
            }


            return Promise.reject(err)
        }
    )
}