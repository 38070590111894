import React from "react";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import {useLocation} from "react-router";
import {Image} from 'react-bootstrap';
import {Divider} from '@material-ui/core';

const Icon = (props) => {
    const {type, value} = props
    let icon = null

    switch (type) {
        case "svg":
            icon = (<SVG className={`svg-icon menu-icon ${value}`} viewBox="0 0 400 300" src={toAbsoluteUrl(value)} />)
            break
        case "image":
            icon = (<Image src={toAbsoluteUrl(value)} style={{height: "20px"}} roundedCircle/>)
            break
        default :
            icon = (<i className={`svg-icon menu-icon ${value}`} />)
            break
    }
    return icon
}


export default ({ layoutProps , item }) => {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };
    return (
        <>
            {/*begin::1 Level*/}
            <li
                className={`menu-item ${getMenuItemActive(item.page, false)}`}
                aria-haspopup="true"
            >
                {item.type !== "divider" ?
                    (item.type !== "external") ?
                        (<NavLink className="menu-link" to={item.page}>
                            <span className="svg-icon menu-icon">
                                {item.icon && <Icon type={item.icon.type} value={item.icon.icon}/>}
                            </span>
                            <span className="menu-text">{item.title}</span>
                        </NavLink>) :
                        (<a className="menu-link" href={item.page} target="_blank">
                            <span className="svg-icon menu-icon">
                                {item.icon && <Icon type={item.icon.type} value={item.icon.icon}/>}
                            </span>
                                <span className="menu-text">{item.title}</span>
                            </a>
                        )
                    :
                    (
                        <>
                            <Divider variant={"fullWidth"} light={true} style={{padding: "10px"}}/>
                        </>
                    )

                }
            </li>
            {/*end::1 Level*/}
        </>
    )


}









/*




{/!* Components *!/}
{/!* begin::section *!/}
<li className="menu-section ">
    <h4 className="menu-text">Components</h4>
    <i className="menu-icon flaticon-more-v2"></i>
</li>
{/!* end:: section *!/}

{/!* Material-UI *!/}
{/!*begin::1 Level*!/}
<li
    className={`menu-item menu-item-submenu ${getMenuItemActive(
        "/google-material",
        true
    )}`}
    aria-haspopup="true"
    data-menu-toggle="hover"
>
    <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
        <span className="menu-text">Material UI</span>
        <i className="menu-arrow" />
    </NavLink>
    <div className="menu-submenu ">
        <i className="menu-arrow" />
        <ul className="menu-subnav">
            <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Material UI</span>
                </span>
            </li>

            {/!* Inputs *!/}
            {/!*begin::2 Level*!/}
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/google-material/inputs",
                    true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
                <NavLink
                    className="menu-link menu-toggle"
                    to="/google-material/inputs"
                >
                    <i className="menu-bullet menu-bullet-dot">
                        <span />
                    </i>
                    <span className="menu-text">Inputs</span>
                    <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item  ${getMenuItemActive(
                                "/google-material/inputs/autocomplete"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/autocomplete"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Autocomplete</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/buttons"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/buttons"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Buttons</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/checkboxes"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/checkboxes"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Checkboxes</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/pickers"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/pickers"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Pickers</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/radio-buttons"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/radio-buttons"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Radio Buttons</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/selects"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/selects"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Selects</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/switches"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/switches"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Switches</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/text-fields"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/text-fields"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Text Fields</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}

                        {/!*begin::3 Level*!/}
                        <li
                            className={`menu-item ${getMenuItemActive(
                                "/google-material/inputs/transfer-list"
                            )}`}
                            aria-haspopup="true"
                        >
                            <NavLink
                                className="menu-link"
                                to="/google-material/inputs/transfer-list"
                            >
                                <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                </i>
                                <span className="menu-text">Transfer List</span>
                            </NavLink>
                        </li>
                        {/!*end::3 Level*!/}
                    </ul>
                </div>
            </li>
            {/!*end::2 Level*!/}
*/
