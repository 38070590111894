export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "Dashboards",
      //   root: true,
      //   alignment: "left",
      //   page: "dashboard",
      //   translate: "MENU.DASHBOARD"
      // },
      // {
      //   title: 'My Page', // <= Title of the page
      //   desc: 'Some my description goes here', // <= Description of the page
      //   root: true,
      //   page: "hello",
      //   alignment: "left",
      //   icon: 'flaticon-line-graph' // <= Choose the icon
      // },
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: {
          icon: "flaticon2-architecture-and-city"
        },
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      {
        title: "Help Desk",
        root: true,
        icon: {
          icon : "fa fa-info-circle"
        },
        page: "helpdesk",
        bullet: "dot"
      },
      {
        title: 'Inventory Editor', // <= Title of the page
        desc: 'Bulk Inventory Editor', // <= Description of the page
        root: true,
        page: "inventory",
        alignment: "left",
        icon: {
          icon: 'fa fa-box-open'
        } // <= Choose the icon
      },
      {
        title: 'ScheduleHead', // <= Title of the page
        desc: 'ScheduleHead', // <= Description of the page
        root: true,
        page: "schedulehead",
        alignment: "left",
        icon: {
          icon : '/media/project/icon/schedulehead.svg',
          type: "svg"
        } // <= Choose the icon
      },
      {
        title: 'Vehicles', // <= Title of the page
        desc: 'Vehicles', // <= Description of the page
        root: true,
        page: "vehicles",
        alignment: "left",
        icon: {
          icon: 'fa fa-truck'
        } // <= Choose the icon
      },
    ]
  }
};


