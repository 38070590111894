import React from "react";
import {useSubheader} from "../../_metronic/layout";
import *as THREE from "three";

export const MyPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("My Custom title");

  let mount
  let canv
  React.useEffect(()=>{
    if (mount) {
      /*var scene = new THREE.Scene();
      var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
      var renderer = new THREE.WebGLRenderer();
      renderer.setSize( window.innerWidth/2, window.innerHeight /2);
      // document.body.appendChild( renderer.domElement );
      // use ref as a mount point of the Three.js scene instead of the document.body
      mount.appendChild( renderer.domElement );
      var geometry = new THREE.BoxGeometry( 1, 1, 1 );
      var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
      var cube = new THREE.Mesh( geometry, material );
      scene.add( cube );
      camera.position.z = 5;
      var animate = function () {
        requestAnimationFrame( animate );
        cube.rotation.x += 0.01;
        cube.rotation.y += 0.01;
        renderer.render( scene, camera );
      };
      animate();*/







      var animation;

      var scene = new THREE.Scene();
      let camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      //Light
      // var regLight = new THREE.Light(0xffffff);
      // scene.add(regLight);

      var ambient = new THREE.AmbientLight(0xffffff);
      scene.add(ambient);

      var lights = [];
      lights[0] = new THREE.PointLight( 0xffffff, 50, 0 );
      lights[1] = new THREE.PointLight( 0xffffff, 50, 0 );
      lights[2] = new THREE.PointLight( 0xffffff, 50, 0 );

      lights[0].position.set( 0, 200, 0 );
      lights[1].position.set( 100, 200, 100 );
      lights[2].position.set( -100, -200, -100 );

      scene.add( lights[0] );
      scene.add( lights[1] );
      scene.add( lights[2] );


      //window.addEventListener( 'resize', onWindowResize, false );


      /*var canv = document.getElementById('canv');*/
      var renderer = new THREE.WebGLRenderer({ 'alpha': true, canvas: canv, antialias: true });
      // var renderer = new THREE.WebGLRenderer({ canvas: canv });
      renderer.shadowMapType = THREE.PCFSoftShadowMap; // options are THREE.BasicShadowMap | THREE.PCFShadowMap | THREE.PCFSoftShadowMap
      renderer.setSize(window.innerWidth, window.innerHeight);

      mount.appendChild( renderer.domElement );

      var polyGeo = new THREE.Geometry();
      polyGeo.vertices.push(new THREE.Vector3(-41,6,352));	// 0 = 1
      polyGeo.vertices.push(new THREE.Vector3(42,-93,148));	// 1 = 2
      polyGeo.vertices.push(new THREE.Vector3(-100,-105,90));	// 2 = 3
      polyGeo.vertices.push(new THREE.Vector3(99,-39,266));	// 3 = 4
      polyGeo.vertices.push(new THREE.Vector3(-145,4,161));	// 4 = 5
      polyGeo.vertices.push(new THREE.Vector3(42,-180,-90));	// 5 = 6
      polyGeo.vertices.push(new THREE.Vector3(-98,-176,-36));	// 6 = 7
      polyGeo.vertices.push(new THREE.Vector3(-229,6,-148));	// 7 = 8
      polyGeo.vertices.push(new THREE.Vector3(-67,-175,-245));// 8 = 9
      polyGeo.vertices.push(new THREE.Vector3(138,-92,-26));	// 9 = 10
      polyGeo.vertices.push(new THREE.Vector3(91,-154,-236));	// 10 = 11
      polyGeo.vertices.push(new THREE.Vector3(185,6,-145));	// 11 = 12
      polyGeo.vertices.push(new THREE.Vector3(39,122,69));	// 12 = 13
      polyGeo.vertices.push(new THREE.Vector3(-105,117,77));	// 13 = 14
      polyGeo.vertices.push(new THREE.Vector3(144,127,-138));	// 14 = 15
      polyGeo.vertices.push(new THREE.Vector3(3,226,-78));	// 15 = 16
      polyGeo.vertices.push(new THREE.Vector3(-42,166,-125));	// 16 = 17
      polyGeo.vertices.push(new THREE.Vector3(17,166,-212));	// 17 = 18
      polyGeo.vertices.push(new THREE.Vector3(-164,97,-259));	// 18 = 19
      polyGeo.vertices.push(new THREE.Vector3(89,115,-319));	// 19 = 20

      var mats = [
        // new THREE.MeshBasicMaterial({color: 0x47a2b4, transparent: true, opacity: 0.5, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}),
        new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide})
      ];

      polyGeo.faces.push(new THREE.Face3(0,1,2, 1,1, 0));
      polyGeo.faces.push(new THREE.Face3(0,1,3, 1,1, 1));
      polyGeo.faces.push(new THREE.Face3(0,2,4, 1,1, 2));
      polyGeo.faces.push(new THREE.Face3(1,2,5, 1,1, 3));
      polyGeo.faces.push(new THREE.Face3(2,5,6, 1,1, 4));
      polyGeo.faces.push(new THREE.Face3(2,6,7, 1,1, 5));
      polyGeo.faces.push(new THREE.Face3(2,4,7, 1,1, 6));
      polyGeo.faces.push(new THREE.Face3(6,7,8, 1,1, 7));
      polyGeo.faces.push(new THREE.Face3(5,6,8, 1,1, 8));
      polyGeo.faces.push(new THREE.Face3(1,3,9, 1,1, 9));
      polyGeo.faces.push(new THREE.Face3(1,5,9, 1,1, 10));
      polyGeo.faces.push(new THREE.Face3(5,8,10, 1,1, 11));
      polyGeo.faces.push(new THREE.Face3(5,9,10, 1,1, 12));
      polyGeo.faces.push(new THREE.Face3(9,10,11, 1,1, 13));
      polyGeo.faces.push(new THREE.Face3(3,9,11, 1,1, 14));
      polyGeo.faces.push(new THREE.Face3(0,3,12, 1,1, 15));
      polyGeo.faces.push(new THREE.Face3(0,12,13, 1,1, 16));
      polyGeo.faces.push(new THREE.Face3(0,4,13, 1,1, 17));
      polyGeo.faces.push(new THREE.Face3(3,11,14, 1,1, 18));
      polyGeo.faces.push(new THREE.Face3(3,12,14, 1,1, 19));
      polyGeo.faces.push(new THREE.Face3(4,7,13, 1,1, 20));
      polyGeo.faces.push(new THREE.Face3(12,14,15, 1,1, 21));
      polyGeo.faces.push(new THREE.Face3(12,13,15, 1,1, 22));
      polyGeo.faces.push(new THREE.Face3(13,15,16, 1,1, 23));
      polyGeo.faces.push(new THREE.Face3(7,13,16, 1,1, 24));
      polyGeo.faces.push(new THREE.Face3(15,16,17, 1,1, 25));
      polyGeo.faces.push(new THREE.Face3(14,15,17, 1,1, 26));
      polyGeo.faces.push(new THREE.Face3(16,17,18, 1,1, 27));
      polyGeo.faces.push(new THREE.Face3(7,16,18, 1,1, 28));
      polyGeo.faces.push(new THREE.Face3(7,8,18, 1,1, 29));
      polyGeo.faces.push(new THREE.Face3(14,17,19, 1,1, 30));
      polyGeo.faces.push(new THREE.Face3(11,14,19, 1,1, 31));
      polyGeo.faces.push(new THREE.Face3(10,11,19, 1,1, 32));
      polyGeo.faces.push(new THREE.Face3(8,10,19, 1,1, 33));
      polyGeo.faces.push(new THREE.Face3(17,18,19, 1,1, 34));
      polyGeo.faces.push(new THREE.Face3(8,18,19, 1,1, 35));


      // var polyMaterial = new THREE.MeshBasicMaterial({
      // 	// color:0xffffff,
      // 	transparent: true,
      // 	opacity: 0.4,
      // 	vertexColors:THREE.VertexColors,
      // 	side:THREE.DoubleSide
      // });
      var polyWireMaterial = new THREE.MeshBasicMaterial({
        color:0xaaaaaa,
        wireframe: true,
        wireframeLinewidth: 1,
        vertexColors:THREE.VertexColors,
        side:THREE.DoubleSide
      });

      // var polyMesh = new THREE.Mesh(polyGeo, new THREE.MeshFaceMaterial(mats));
      var polyMesh = new THREE.SceneUtils.createMultiMaterialObject(polyGeo, [
        new THREE.MeshFaceMaterial(mats),
        polyWireMaterial
      ]);


      polyMesh.rotation.set(-1.5,0,1.1);
      polyMesh.position.set(0,0,120);
      scene.add(polyMesh);

      polyGeo.computeBoundingSphere();


      var fog = new THREE.Fog(0x000000, 350, 1533);


      // scene.add(line);
      scene.fog = fog;

      // scene.add(fog);
      // scene.rotation.y = 0.65;

      // camera.rotation.z -= 1.5;
      camera.position.z = 1600;
      /*$('.spin').on('click', function(){
        if(spin == 1) {
          spin = 0;
          $(this).find('.spin_status').text('Off');
          $('.menu').fadeIn(500);
        } else {
          spin = 1;
          $(this).find('.spin_status').text('On');
          $('.menu').fadeOut(500);
        }
      });

      newMesh = 0;
      lineMesh = 0;
      cube = 0;
      // cube2 = 0;
      newTextMesh = 0;
      newTextMesh2 = 0;
      locationTextMesh = 0;
      detailsTextMesh = 0;
      override = 1;
      var upVerts;
      var slideCrystal;

      function toScreenPosition(obj, camera) {
        var vector = new THREE.Vector3();

        var widthHalf = 0.5*renderer.context.canvas.width;
        var heightHalf = 0.5*renderer.context.canvas.height;
        if(obj) {
          obj.updateMatrixWorld();
          vector.setFromMatrixPosition(obj.matrixWorld);
          vector.project(camera);

          vector.x = ( vector.x * widthHalf ) + widthHalf;
          vector.y = - ( vector.y * heightHalf ) + heightHalf;

          return {
            x: vector.x,
            y: vector.y
          };
        }
        return {
          x: 0,
          y: 0
        };
      }

      function menuTriggered(i, get) {
        $('.cover_img').removeClass('active');
        if($(window).width() > 900) {
          if(i == -1) {
            var newIndex = $('.menu ul li').index($('.menu ul li').eq(get - 1));
          } else {
            var newIndex = $('.menu ul li').index(i);
          }
          var curIndex = $('.menu ul li').index($('.menu ul .selected'));
        } else {
          if(i == -1) {
            var newIndex = $('.menu_mobile ul li').index($('.menu_mobile ul li').eq(get - 1));
          } else {
            var newIndex = $('.menu_mobile ul li').index(i);
          }
          var curIndex = $('.menu_mobile ul li').index($('.menu_mobile ul .selected'));
        }


        // polyMesh.children[0].geometry.faces[1].materialIndex = 1; // DOESNT WORK
        var fLength = polyMesh.children[0].geometry.faces.length;

        var matchI = newIndex;

        while(matchI >= fLength) {
          matchI = matchI - fLength;
        }

        spin = 0;
        $('.spin_status').text('Off');
        $('.menu').fadeIn(500);

        getShape = newIndex + 2;

        if($(window).width() > 900) {
          if(cube != 0) {
            scene.remove(cube);
          }
          // if(cube2 != 0) {
          // scene.remove(cube2);
          // }
          if(lineMesh != 0) {
            scene.remove(lineMesh);
          }
          if(newTextMesh != 0) {
            scene.remove(newTextMesh);
          }
          if(newTextMesh2 != 0) {
            scene.remove(newTextMesh2);
          }
          if(locationTextMesh != 0) {
            scene.remove(locationTextMesh);
          }
          if(detailsTextMesh != 0) {
            scene.remove(detailsTextMesh);
          }
        } else {
          scene.remove(cube);
          // scene.remove(cube2);
          scene.remove(lineMesh);
          scene.remove(newTextMesh);
          scene.remove(newTextMesh2);
          scene.remove(locationTextMesh);
          scene.remove(detailsTextMesh);
        }

        var elem = renderer.domElement,
            boundingRect = elem.getBoundingClientRect(),
            x = (boundingRect.width),
            y = (boundingRect.height);

        var xSet;
        if(x > 900 && x <= 1020) {
          xSet = ((x / 2) - 290);
        } else if(x > 1020 && x < 1200) {
          xSet = ((x / 2) - 340);
        } else {
          xSet = ((x / 2) - 390);
        }

        if($(window).width() > 900) {
          if(newMesh != 0) {
            updateVerts = 1;
            clearTimeout(upVerts);
            upVerts = setTimeout(function(){
              updateVerts = 0;
              passx1 = 0;
              passy1 = 0;
              passx2 = 0;
              passy2 = 0;
            }, 2522);
            // scene.remove(newMesh);
          } else {
            var newShape = new THREE.Geometry();
            var material = [new THREE.MeshBasicMaterial({
              transparent: true,
              opacity: 0,
              color: 0x47a2b4,
              side: THREE.DoubleSide
            })];
            if(getShape % 3 == 0) {
              newShape.vertices.push(new THREE.Vector3(0,0,0));	// 0 = 1
              newShape.vertices.push(new THREE.Vector3(-85,-260,0));	// 1 = 2
              newShape.vertices.push(new THREE.Vector3(60,-170,0));	// 2 = 3
            } else if(getShape % 2 == 0) {
              newShape.vertices.push(new THREE.Vector3(0,0,0));	// 0 = 1
              newShape.vertices.push(new THREE.Vector3(-70,-160,0));	// 1 = 2
              newShape.vertices.push(new THREE.Vector3(90,-260,0));	// 2 = 3
            } else {
              newShape.vertices.push(new THREE.Vector3(0,0,0));	// 0 = 1
              newShape.vertices.push(new THREE.Vector3(-30,-260,0));	// 1 = 2
              newShape.vertices.push(new THREE.Vector3(150,-90,0));	// 2 = 3
            }

            newShape.computeBoundingSphere();

            newShape.faces.push(new THREE.Face3(0,1,2, 1,1, 0));
            newMesh = new THREE.Mesh(newShape, new THREE.MeshFaceMaterial(material));
            newMesh.position.set(xSet - 30,280, 50);
            scene.add(newMesh);
          }
        }


        if(x < 1020) {
          var clickBoxSetX = ((x / 2) - 100);
        } else {
          var clickBoxSetX = 400;
        }

        var listElem = $('.menu ul li:nth-child('+(newIndex+1)+')');
        var titleText = listElem.text();
        if(titleText.length > 16) {
          // titleSplit = titleText.split(' ');

          titleSplit1 = titleText.substring(0, titleText.indexOf(" ", 16)).trim();
          titleSplit2 = titleText.substring(titleText.indexOf(" ", 16)).trim();
          if(titleSplit1 == '' || titleSplit1 == ' ') {
            var tType = 'single';
          } else {
            var tType = 'double';
          }
        } else {
          var tType = 'single';
        }
        var locationText = listElem.attr('location');

        if(tType == 'single') {
          shapes = THREE.FontUtils.generateShapes( titleText, {
            size: 23,
            weight: 'bold'
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          newTextMesh = new THREE.Mesh( geom, mat );

          newTextMesh.position.set((xSet - 45), 135, 51);
          if($(window).width() > 900) {
            scene.add(newTextMesh);
          }

          shapes = THREE.FontUtils.generateShapes( locationText, {
            size: 20
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          locationTextMesh = new THREE.Mesh( geom, mat );

          locationTextMesh.position.set((xSet - 45), 100, 51);
          if($(window).width() > 900) {
            scene.add(locationTextMesh);
          }


          shapes = THREE.FontUtils.generateShapes( 'View Details', {
            size: 16
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          detailsTextMesh = new THREE.Mesh( geom, mat );

          detailsTextMesh.position.set((xSet + 75), 45, 55);

          geom = new THREE.BoxGeometry( 150, 40, 1 );
          mate = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0x47a2b4,
            side: THREE.DoubleSide
          });
          // cube2 = new THREE.Mesh( geom, mate );
          // cube2.position.set((xSet - 45 + 75), 53, 53);

          if($(window).width() > 900) {
            scene.add(detailsTextMesh);
            // scene.add(cube2);
          }


          box = new THREE.Box3().setFromObject( newTextMesh );

          geom = new THREE.BoxGeometry( 200, 10, 1 );
          mate = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          cube = new THREE.Mesh( geom, mate );
          cube.position.set((xSet - 45 + 100), 185, 51);

        } else if(tType == 'double') {
          shapes = THREE.FontUtils.generateShapes( titleSplit1, {
            size: 23,
            weight: 'bold'
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          newTextMesh = new THREE.Mesh( geom, mat );

          newTextMesh.position.set((xSet - 45), 135, 51);
          if($(window).width() > 900) {
            scene.add(newTextMesh);
          }

          shapes = THREE.FontUtils.generateShapes( titleSplit2, {
            size: 23,
            weight: 'bold'
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          newTextMesh2 = new THREE.Mesh( geom, mat );

          newTextMesh2.position.set((xSet - 45), 135 - 35, 51);
          if($(window).width() > 900) {
            scene.add(newTextMesh);
            scene.add(newTextMesh2);
          }

          shapes = THREE.FontUtils.generateShapes( locationText, {
            size: 20
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          locationTextMesh = new THREE.Mesh( geom, mat );

          locationTextMesh.position.set((xSet - 45), 100 - 35, 51);
          if($(window).width() > 900) {
            scene.add(locationTextMesh);
          }


          shapes = THREE.FontUtils.generateShapes( 'View Details', {
            size: 16
          } );
          geom = new THREE.ShapeGeometry( shapes );
          mat = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          detailsTextMesh = new THREE.Mesh( geom, mat );

          detailsTextMesh.position.set((xSet + 75), 45 - 35, 55);

          geom = new THREE.BoxGeometry( 150, 40, 1 );
          mate = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0x47a2b4,
            side: THREE.DoubleSide
          });
          // cube2 = new THREE.Mesh( geom, mate );
          // cube2.position.set((xSet - 45 + 75), 53 - 35, 53);

          if($(window).width() > 900) {
            scene.add(detailsTextMesh);
            // scene.add(cube2);
          }


          box = new THREE.Box3().setFromObject( newTextMesh );

          geom = new THREE.BoxGeometry( 200, 10, 1 );
          mate = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: 0,
            color: 0xffffff,
            side: THREE.DoubleSide
          });
          cube = new THREE.Mesh( geom, mate );
          cube.position.set((xSet - 45 + 100), 185, 51);
        }

        if($(window).width() > 900 && newMesh) {
          scene.add( cube );
          if(newMesh != 0) {
            forceFade(newMesh, 'in');
          }

          var proj = toScreenPosition(newMesh, camera);

          var divElem = $('.clickBox');
          divElem.css({
            'left': (proj.x - 55) + 'px',
            'top' : (proj.y - 10) + 'px',
            'width' : (box.size().x + 365) + 'px'
          });

          divElem.attr('targ-id', listElem.attr('event-id'));
          divElem.attr('href', '#'+ titleText.replace(/ /g, "-"));


        } else {
          var divElem = $('.mobile_call_to_action');
          divElem.addClass('active');
          divElem.attr('targ-id', listElem.attr('event-id'));
          divElem.attr('href', '#'+ titleText.replace(/ /g, "-"));
        }



        var dif = newIndex - curIndex;
        if(dif != 0 || override || get > 0) {
          override = 0;
          if(get > 0) {
            dif = get - gIndex;
            if(dif != 0) {
              moveCrystal(dif);
            }
          } else {
            moveCrystal(dif);
            if($(window).width() > 900) {
              adjustMenu(newIndex);
              $('.menu ul li').removeClass('faded');
              $('.menu ul li:nth-child(-n+'+newIndex+')').addClass('faded');
              $('.menu ul li').removeClass('selected');

              $('.menu_mobile ul li').removeClass('faded');
              $('.menu_mobile ul li:nth-child(-n+'+newIndex+')').addClass('faded');
              $('.menu_mobile ul li').removeClass('selected');
              var d = $(i).attr('d-id');
              $('.menu ul li[d-id='+d+']').addClass('selected');
              $('.menu_mobile ul li[d-id='+d+']').addClass('selected');
            } else {
              $('.menu ul li').removeClass('selected');
              $('.menu_mobile ul li').removeClass('selected');
              var d = $(i).attr('d-id');
              $('.menu ul li[d-id='+d+']').addClass('selected');
              $('.menu_mobile ul li[d-id='+d+']').addClass('selected');
            }
          }
        }
        if($(window).width() > 900) {
          coverImg = $('.menu ul .selected').attr('img-url');
          if($('.menu ul .selected').attr('loaded') != 'true') {
            setTimeout(function(){
              $('<img/>').attr('src', 'img/'+coverImg).load(function() {
                $('.menu ul .selected').attr('loaded','true');
                $(this).remove(); // prevent memory leaks as @benweet suggested
                $('.cover_img').css('background', "url('img/"+coverImg+"') no-repeat center center");
                setTimeout(function(){
                  $('.cover_img').addClass('active');
                }, 8);
              });
            }, 1222);
          } else {
            setTimeout(function(){
              $('.cover_img').css('background', "url('img/"+coverImg+"') no-repeat center center");
              setTimeout(function(){
                $('.cover_img').addClass('active');
              }, 8);
            }, 1222);
          }
        }
      }

      $('.menu ul li, .menu_mobile ul li').on('click', function() {
        if(paused !== 1) {
          menuTriggered(this);
        }
      });

      $('.menu2 ul li').on('click', function() {
        if(paused !== 1) {
          var d = $(this).attr('d-id');
          var otherMenu = $('.menu ul li[d-id='+d+']');
          menuTriggered(otherMenu);
        }
      });

      $('.main_heading .override').on('click', function(){
        if($('body').hasClass('open')) {
          $('.info_content').fadeOut(600);
          $('.info_content_images').fadeOut(600);
          $('.clickBox').removeClass('disappear');
          $('.mobile_call_to_action').removeClass('disappear');
          $('.menu, .menu2, .main_heading, .menu_mobile').removeClass('away');
          $('body').removeClass('open');
          sideCrystal = 2;
          saveSideCrystal = 2;
          clearTimeout(slideCrystal);
          slideCrystal = setTimeout(function(){
            sideCrystal = 0;
            $('.menu, .menu2, .main_heading, .menu_mobile').removeClass('average');
          }, 1100);
        }
        n = $('.menu ul li:first');
        if(!n.hasClass('selected')) {
          n.click();
        }
      });

      $('.back_button').on('click', function(){
        if($('body').hasClass('open')) {
          $('.info_content').fadeOut(600);
          $('.info_content_images').fadeOut(600);
          $('.clickBox').removeClass('disappear');
          $('.mobile_call_to_action').removeClass('disappear');
          $('.menu, .menu2, .main_heading, .menu_mobile').removeClass('away');
          $('body').removeClass('open');
          sideCrystal = 2;
          saveSideCrystal = 2;
          clearTimeout(slideCrystal);
          slideCrystal = setTimeout(function(){
            sideCrystal = 0;
            $('.menu, .menu2, .main_heading, .menu_mobile').removeClass('average');
            window.location.hash = '';
          }, 1100);
        }
      });
      $('.clickBox, .mobile_call_to_action').on('click', function() {
        if(paused === 1) {
          return;
        }
        $('.loading_div span').addClass('active');
        t = $(this);
        targId = t.attr('targ-id');
        $.ajax({
          url: "/Events/getinfo/"+targId,
          type: "POST",
          dataType: "json",
          success: function(data, text, xhr) {
            console.log(data);
            if(data[0] == 'error') {
              alert(data[1]);
            } else if(data[0] == 'success') {

              $('.title_heading').html(data[1].Event.title);
              $('.location_heading').html(data[1].Event.location);
              $('.mobile_content').html(data[1].Event.body);
              $('.date_heading').html(data[1].Event.event_date);
              var vid = data[1].Event.video_link;
              var img = data[1].Event.img;
              var imgs = img.split(",");
              $('.mobile_images').html('');
              if($('.info_content_images').find('.simplebar-content').length) {
                $('.info_content_images').find('.simplebar-content').html('');
                if(vid != '' && vid.indexOf('youtube') > -1) {
                  vid = vid.replace('watch?v=', 'embed/');
                  console.log('vid1', vid);
                  $('.mobile_images').append('<iframe src="'+vid+'" frameborder="0" width="100%" height="350" allowfullscreen></iframe>');
                  $('.info_content_images').find('.simplebar-content').append('<iframe src="'+vid+'" frameborder="0" width="100%" height="500" allowfullscreen></iframe>');
                }
                if(img != '') {
                  for (var i = 0; i < imgs.length; i++) {
                    var im = imgs[i].replace(/\s+/g, ' ').trim();
                    $('.mobile_images').append('<img src="img/'+im+'" style="display:none;"/>');
                    $('.info_content_images').find('.simplebar-content').append('<img src="img/'+im+'" style="display:none;"/>');
                  }
                }
              } else {
                $('.info_content_images').html('');
                if(vid != '' && vid.indexOf('youtube') > -1) {
                  vid = vid.replace('watch?v=', 'embed/');
                  console.log('vid2', vid);
                  $('.mobile_images').append('<iframe src="'+vid+'" frameborder="0" width="100%" height="350" allowfullscreen></iframe>');
                  $('.info_content_images').append('<iframe src="'+vid+'" frameborder="0" width="100%" height="500" allowfullscreen></iframe>');
                }
                if(img != '') {
                  for (var i = 0; i < imgs.length; i++) {
                    var im = imgs[i].replace(/\s+/g, ' ').trim();
                    $('.mobile_images').append('<img src="img/'+im+'" style="display:none;"/>');
                    $('.info_content_images').append('<img src="img/'+im+'" style="display:none;"/>');
                  }
                }
              }


              t.addClass('disappear');
              $('.menu, .menu2, .main_heading, .menu_mobile').addClass('away');
              $('.menu, .menu2, .main_heading, .menu_mobile').addClass('average');
              $('body').addClass('open');
              sideCrystal = 1;
              saveSideCrystal = 1;
              clearTimeout(slideCrystal);
              $('.info_content').fadeIn(1600);
              var i_ = $('.info_content_images');
              var i_left = parseInt(i_.css('left'), 10);
              var i_right = parseInt(i_.css('right'), 10);
              var i_top = parseInt(i_.css('top'), 10);
              var i_bottom = parseInt(i_.css('bottom'), 10);
              var i_winH = $(window).height();
              var i_winW = $(window).width();
              var i_he = i_winH - (i_top + i_bottom);
              var i_wi = i_winW - (i_left + i_right);

              var infoC = $('.info_content').height();
              var paragraph = infoC - ($('.huge_line').height() + $('.title_heading').height() + 15 + $('.location_heading').height() + 30);
              $('p.simplebar').height(paragraph);

              i_.css({
                'height': i_he,
                'width' : i_wi
              });
              i_.fadeIn(1600);

              if($('.info_content').find('.simplebar-content').length) {
                $('.info_content p').simplebar('recalculate');
              } else {
                $('.info_content p').simplebar({ autoHide: false });
              }
              if($('.info_content_images').hasClass('simp')) {
                $('.info_content_images').simplebar('recalculate');
              } else {
                $('.info_content_images').simplebar({ autoHide: false });
                $('.info_content_images').addClass('simp');
              }
              slideCrystal = setTimeout(function(){
                sideCrystal = 0;
              }, 2500);

              setTimeout(function(){
                $('.mobile_images').find('img').each(function(){
                  $(this).fadeIn(666);
                });
                $('.info_content_images').find('img').each(function(){
                  $(this).fadeIn(666);
                });

                $('.mobile_images').find('iframe').each(function(){
                  $(this).fadeIn(666);
                });
                $('.info_content_images').find('iframe').each(function(){
                  $(this).fadeIn(666);
                });
              }, 1000);


            } else if(data[0] != 'success') {
              alert('Error retrieving event data. Please try again.');
            }
            setTimeout(function(){
              $('.loading_div span').removeClass('active');
            }, 666);
          },
          error: function(xhr, textStatus, error) {
            console.log(xhr);
            console.log(textStatus);
            console.log(error);
            alert('Error retrieving event data. Please, try again.');
            setTimeout(function(){
              $('.loading_div span').removeClass('active');
            }, 666);
          }
        });

      });

      gIndex = $('.menu ul li.selected').attr('d-id');
      fadeObjIn = 0;
      updateVerts = 0;
      passx1 = 0;
      passx2 = 0;
      passy1 = 0;
      passy2 = 0;
      sideCrystal = 0;
      saveSideCrystal = 0;

      function forceFade(tar, direction) {
        fadeObjIn = 1;
        setTimeout(function(){
          fadeObjIn = 0;
        }, 1000);
      }

      function moveCrystal(number) {
        // animate();
        paused = 1;
        rotateRate = number;
        gIndex = $('.menu ul li.selected').attr('d-id');

        setTimeout(function(){
          rotateRate = false;
          setTimeout(function(){
            paused = 0;
          }, 200);

        }, 1000);
      }

      var zoom = false;
      var spin = 1;
      var speed;
      var rotateRate = false;
      var paused = 0;
      var last_position = {
        x: polyMesh.rotation.x,
        y: polyMesh.rotation.y
      };

      //Editable variables
      var rotateSpeed = 1.2; //Higher number, faster speed
      var maxRotation = 0.075; //the lower, the less movement

      function diff(num1, num2){
        if(num1 > num2) {
          return num1-num2;
        } else {
          return num2-num1;
        }
      }

      function render() {
        requestAnimationFrame(render);
        if(rotateRate != false) {
          // polyMesh.rotation.set(-1.5,0,1.1);

          // polyMesh.rotation.y(-1.5, last_position.x, 1.1);
          polyMesh.rotation.z -= ((0.15 * rotateRate) / (60 / rotateSpeed));
        }
        last_position = {
          x: 0,
          y: polyMesh.rotation.y
        };
        if(zoom !== false) {
          if(zoom < 180) {
            zoom += 1;
            camera.position.z -= speed;
            speed -= 0.038888888888;
          } else {
            zoom = false;
            spin = 0;
          }
        }
        //This is where the magic happens - make changes here - this runs, and triggers every frame - 60 FPS
        // var e = window.event;

        // var mouseX = e.clientX;
        // var mouseY = e.clientY;

        // camera.lookAt( scene.position );
        // scene.rotation.x += 0.045;
        if(spin == 1) {
          if(zoom == false) {
            zo = 1;
          } else {
            zo = zoom;
          }
          spinrate = 2 / zo;
          polyMesh.rotation.z -= spinrate;
        }
        if(fadeObjIn == 1) {
          newMesh.material.materials[0].opacity += (1 / 27);
          cube.material.opacity += (1 / 27);
          // cube2.material.opacity += (1 / 27);
          newTextMesh.material.opacity += (1 / 27);
          if(newTextMesh2 != 0) {
            newTextMesh2.material.opacity += (1 / 27);
          }
          locationTextMesh.material.opacity += (1 / 27);
          detailsTextMesh.material.opacity += (1 / 27);
        }
        if(sideCrystal == 1) {
          var elem = renderer.domElement,
              boundingRect = elem.getBoundingClientRect(),
              x = (boundingRect.width),
              y = (boundingRect.height);

          var proj = toScreenPosition(polyMesh, camera);
          var calc = (x - proj.x) / 10;
          polyMesh.rotation.z += calc / 500;
          scene.position.x += calc;
        } else if(sideCrystal == 2) {
          var elem = renderer.domElement,
              boundingRect = elem.getBoundingClientRect(),
              x = (boundingRect.width),
              y = (boundingRect.height);

          var proj = toScreenPosition(polyMesh, camera);
          var calc = (proj.x - (x / 2)) / 10;
          polyMesh.rotation.z -= calc / 500;
          scene.position.x -= calc;
        }
        if(updateVerts == 1) {
          if(getShape % 3 == 0) {
            new1x = -85;
            new1y = -260;
            new2x = 60;
            new2y = -170;
          } else if(getShape % 2 == 0) {
            new1x = -70;
            new1y = -160;
            new2x = 90;
            new2y = -260;
          } else {
            new1x = -30;
            new1y = -260;
            new2x = 150;
            new2y = -90;
          }

          currX1 = newMesh.geometry.vertices[1].x;
          currY1 = newMesh.geometry.vertices[1].y;
          currX2 = newMesh.geometry.vertices[2].x;
          currY2 = newMesh.geometry.vertices[2].y;

          if(passx1 != 1) {
            if(currX1 < new1x) {
              if(diff(currX1, new1x) < 2) {
                if(diff(currX1, new1x) < 1) {
                  passx1 = 1;
                } else {
                  newMesh.geometry.vertices[1].x += 1.5;
                }
              } else {
                newMesh.geometry.vertices[1].x += 3;
              }
            } else if(currX1 > new1x) {
              if(diff(currX1, new1x) < 2) {
                if(diff(currX1, new1x) < 1) {
                  passx1 = 1;
                } else {
                  newMesh.geometry.vertices[1].x -= 1.5;
                }
              } else {
                newMesh.geometry.vertices[1].x -= 3;
              }
            } else {
              passx1 = 1;
            }
          }


          if(passy1 != 1) {
            if(currY1 < new1y) {
              if(diff(currY1, new1y) < 2) {
                if(diff(currY1, new1y) < 1) {
                  passy1 = 1;
                } else {
                  newMesh.geometry.vertices[1].y += 1.5;
                }
              } else {
                newMesh.geometry.vertices[1].y += 3;
              }
            } else if(currY1 > new1y) {
              if(diff(currY1, new1y) < 2) {
                if(diff(currY1, new1y) < 1) {
                  passy1 = 1;
                } else {
                  newMesh.geometry.vertices[1].y -= 1.5;
                }
              } else {
                newMesh.geometry.vertices[1].y -= 3;
              }
            } else {
              passy1 = 1;
            }
          }


          if(passx2 != 1) {
            if(currX2 < new2x) {
              if(diff(currX2, new2x) < 2) {
                if(diff(currX2, new2x) < 1) {
                  passx2 = 1;
                } else {
                  newMesh.geometry.vertices[2].x += 1.5;
                }
              } else {
                newMesh.geometry.vertices[2].x += 3;
              }
            } else if(currX2 > new2x) {
              if(diff(currX2, new2x) < 2) {
                if(diff(currX2, new2x) < 1) {
                  passx2 = 1;
                } else {
                  newMesh.geometry.vertices[2].x -= 1.5;
                }
              } else {
                newMesh.geometry.vertices[2].x -= 3;
              }
            } else {
              passx2 = 1;
            }
          }


          if(passy2 != 1) {
            if(currY2 < new2y) {
              if(diff(currY2, new2y) < 2) {
                if(diff(currY2, new2y) < 1) {
                  passy2 = 1;
                } else {
                  newMesh.geometry.vertices[2].y += 1.5;
                }
              } else {
                newMesh.geometry.vertices[2].y += 2;
              }
            } else if(currY2 > new2y) {
              if(diff(currY2, new2y) < 2) {
                if(diff(currY2, new2y) < 1) {
                  passy2 = 1;
                } else {
                  newMesh.geometry.vertices[2].y -= 1.5;
                }
              } else {
                newMesh.geometry.vertices[2].y -= 2;
              }
            } else {
              passy2 = 1;
            }
          }

          newMesh.geometry.verticesNeedUpdate = true;

          if(passx1 == 1 && passy1 == 1 && passx2 == 1 && passy2 == 1) {
            updateVerts = 0;
            passx1 = 0;
            passy1 = 0;
            passx2 = 0;
            passy2 = 0;
            clearTimeout(upVerts);
          }
        }
        renderer.render(scene, camera);
      }


      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( window.innerWidth, window.innerHeight );

        var elem = renderer.domElement,
            boundingRect = elem.getBoundingClientRect(),
            x = (boundingRect.width),
            y = (boundingRect.height);

        var xSet;
        if(x > 900 && x <= 1020) {
          xSet = ((x / 2) - 300);
        } else if(x > 1020 && x < 1200) {
          xSet = ((x / 2) - 350);
        } else {
          xSet = ((x / 2) - 400);
        }

        if(newMesh != 0) {
          newMesh.position.set(xSet - 30, 280,  50);
          if(newTextMesh2 != 0) {
            var ex = 35;
            newTextMesh2.position.set((xSet - 45), 135 - ex, 51);
          } else {
            var ex = 0;
          }
          newTextMesh.position.set((xSet - 45), 135, 51);
          locationTextMesh.position.set((xSet - 45), 100 - ex, 51);
          detailsTextMesh.position.set((xSet + 75), 45 - ex, 55);
          cube.position.set((xSet - 45 + 100), 185, 51);
          // cube2.position.set((xSet - 45 + 75), 53 - ex, 53);
        }
        if(window.innerWidth < 900) {
          scene.remove(cube);
          // scene.remove(cube2);
          scene.remove(lineMesh);
          scene.remove(newTextMesh);
          scene.remove(newTextMesh2);
          scene.remove(locationTextMesh);
          scene.remove(detailsTextMesh);
          scene.remove(newMesh);

          cube = 0;
          // cube2 = 0;
          lineMesh = 0;
          newTextMesh = 0;
          newTextMesh2 = 0;
          locationTextMesh = 0;
          detailsTextMesh = 0;
          newMesh = 0;
        }

        sideCrystal = saveSideCrystal;
        setTimeout(function(){
          sideCrystal = 0;
        }, 1000);
        // render();
      }

      function move(event){
        if(paused == 0 && spin == 0) {
          var x_pos = event.clientX;
          var x_win = window.innerWidth;

          var y_pos = event.clientY;
          var y_win = window.innerHeight;

          var y_calc= -(
              maxRotation *
              (x_pos / x_win) -
              (maxRotation / 2)
          ) + last_position.y;
          var x_calc= -(
              maxRotation *
              (y_pos / y_win) -
              (maxRotation / 2)
          ) + last_position.x;

          scene.rotation.set(x_calc, y_calc, 0);
        }
      }


      $(window).load(function(){
        render();
        $('canvas').fadeIn(2000);
        $('.info_links').fadeIn(2000);
        $('.logo').fadeIn(2000);

        $('.menu').fadeIn(2000);
        $('.menu2').fadeIn(2000);


        $('.main_heading').fadeIn(6000);

        var wH = $(window).height();

        if($(window).width() <= 900) {
          var top = 30;
          var mhTop = top;
          $('.menu_mobile').fadeIn(4000);
          $('.menu_mobile').height(wH - (126 + 30 + 75));
          $('.menu_mobile.simplebar').simplebar({ autoHide: false });
        } else {
          var top = wH / 2.25;
          var mhH = 126;
          var mhTop = top - mhH - 20;
        }

        // var top = wH / 2.25;
        $('.main_heading').css('top', mhTop);

        // $('.menu ul li.selected').click();
        setTimeout(function(){
          $('.large-dash').addClass('active');
          // $('.main_heading').addClass('active');
          $('.menu').removeClass('slow');
          $('.menu2').removeClass('slow');
          $('.social_section').fadeIn(2000);
        }, 2000);
        zoom = 0;
        speed = 8.2;

        adjustMenu(0);
        if(window.location.hash != '') {
          if($(window).width() > 900) {
            var h = window.location.hash.substring(1),
                rep2 = h.replace(/-/g, " "),
                rep = rep2.replace(/   /g, " - "),
                t = $(".menu ul li:contains('"+rep+"')"),
                ind = $('.menu ul li').index(t),
                time = 3000;
          } else {
            var h = window.location.hash.substring(1),
                rep2 = h.replace(/-/g, " "),
                rep = rep2.replace(/   /g, " - "),
                t = $(".menu_mobile ul li:contains('"+rep+"')"),
                ind = $('.menu_mobile ul li').index(t),
                time = 1500;
          }
          console.log(ind);
          if(ind === -1) {
            history.pushState("", document.title, window.location.pathname);
            adjustMenu(0);
            return;
          }
          setTimeout(function(){
            t.trigger('click');
            paused = 1;
            setTimeout(function(){
              paused = 0;
              if($(window).width() > 900) {
                $('.clickBox').trigger('click');
              } else {
                $('.mobile_call_to_action').trigger('click');
              }
            }, 820);
          }, time);
        }
      });

      $(document).on('keydown', function(e) {
        if(paused !== 1 && $(window).width() > 900 && !$('body').hasClass('open')) {
          console.log(e.which);
          if((e.which || e.keyCode) == 38) { // up
            $('.menu').addClass('stop');
            $('.menu2').addClass('stop');
            // Scroll up
            var current = $('li.selected');
            var dId = current.attr('d-id');
            if(dId != 1) {
              var newDId = dId - 1;
              if(newDId > 0) {
                adjustMenu(newDId-1);
                $('.menu ul li').removeClass('faded');
                $('.menu ul li:nth-child(-n+'+(newDId-1)+')').addClass('faded');
                $('.menu ul li').removeClass('selected');
                n = $('.menu ul li').eq(newDId - 1);
                n.addClass('selected');

                clearTimeout(scrollCheck);
                scrollCheck = setTimeout(function(){
                  menuTriggered(-1, newDId);
                }, 777);
              }
            }
            $('.menu').removeClass('stop');
            $('.menu2').removeClass('stop');
          } else if((e.which || e.keyCode) == 40) { // down
            $('.menu').addClass('stop');
            $('.menu2').addClass('stop');
            // Scroll down
            var current = $('li.selected');
            var dId = current.attr('d-id');
            var last = $('.menu ul li:last').attr('d-id');
            if(dId != last) {
              var newDId = parseInt(dId, 10) + 1;
              if(newDId <= last) {
                adjustMenu(newDId-1);
                $('.menu ul li').removeClass('faded');
                $('.menu ul li:nth-child(-n+'+(newDId-1)+')').addClass('faded');
                $('.menu ul li').removeClass('selected');
                n = $('.menu ul li').eq(newDId - 1);
                n.addClass('selected');

                clearTimeout(scrollCheck);
                scrollCheck = setTimeout(function(){
                  menuTriggered(-1, newDId);
                }, 400);
              }
            }
            $('.menu').removeClass('stop');
            $('.menu2').removeClass('stop');
          }
        }
      });

      var scrollCheck;

      $(document).on('mousewheel', '.menu', function(event){
        if(paused !== 1 && $(window).width() > 900) {
          $('.menu').addClass('stop');
          $('.menu2').addClass('stop');
          if (event.originalEvent.wheelDelta >= 0) {
            // Scroll up
            var current = $('li.selected');
            var dId = current.attr('d-id');
            if(dId != 1) {
              var newDId = dId - 1;
              if(newDId > 0) {
                adjustMenu(newDId-1);
                $('.menu ul li').removeClass('faded');
                $('.menu ul li:nth-child(-n+'+(newDId-1)+')').addClass('faded');
                $('.menu ul li').removeClass('selected');
                n = $('.menu ul li').eq(newDId - 1);
                n.addClass('selected');

                clearTimeout(scrollCheck);
                scrollCheck = setTimeout(function(){
                  menuTriggered(-1, newDId);
                }, 777);
              }
            }
          }
          else {
            // Scroll down
            var current = $('li.selected');
            var dId = current.attr('d-id');
            var last = $('.menu ul li:last').attr('d-id');
            if(dId != last) {
              var newDId = parseInt(dId, 10) + 1;
              if(newDId <= last) {
                adjustMenu(newDId-1);
                $('.menu ul li').removeClass('faded');
                $('.menu ul li:nth-child(-n+'+(newDId-1)+')').addClass('faded');
                $('.menu ul li').removeClass('selected');
                n = $('.menu ul li').eq(newDId - 1);
                n.addClass('selected');

                clearTimeout(scrollCheck);
                scrollCheck = setTimeout(function(){
                  menuTriggered(-1, newDId);
                }, 400);
              }
            }
          }
          $('.menu').removeClass('stop');
          $('.menu2').removeClass('stop');
        }
      });

      $(window).resize(function(){
        adjustMenu($('.menu ul li').index($('.menu ul .selected')));

        var current = $('.menu ul li.selected');
        var dId = current.attr('d-id');
        $('.menu ul li').removeClass('faded');
        $('.menu ul li:nth-child(-n+'+(dId-1)+')').addClass('faded');
        $('.menu ul li').removeClass('selected');
        n = $('.menu ul li').eq(dId - 1);
        n.addClass('selected');

        var wH = $(window).height();
        var wW = $(window).width();


        if(wW > 900 && newTextMesh) {
          box = new THREE.Box3().setFromObject( newTextMesh );
          proj = toScreenPosition(newMesh, camera);

          var divElem = $('.clickBox');
          divElem.css({
            'left': (proj.x - 55) + 'px',
            'top' : (proj.y - 10) + 'px',
            'width' : (box.size().x + 365) + 'px'
          });
        }

        var top = wH / 2.25;
        var mhH = 126;
        var mhTop = top - mhH - 20;

        if(wW <= 900) {
          $('.menu_mobile').show();
          $('.menu_mobile').height(wH - (mhH + 30 + 75));
          if($('.menu_mobile').find('.simplebar-track').length) {
            $('.menu_mobile.simplebar').simplebar('recalculate');
          } else {
            $('.menu_mobile.simplebar').simplebar({ autoHide: false });
          }
          $('.main_heading').css('top', 30);
        } else {
          $('.menu_mobile').hide();
          $('.main_heading').css('top', mhTop);
        }
        if($('.info_content').length) {

          if($('.info_section p').find('.simplebar-scroll-content')) {
            $('.info_section p').simplebar('recalculate');
          } else {
            $('.info_section p').simplebar({ autoHide: false });
          }


          var i_ = $('.info_content_images');
          var i_left = parseInt(i_.css('left'), 10);
          var i_right = parseInt(i_.css('right'), 10);
          var i_top = parseInt(i_.css('top'), 10);
          var i_bottom = parseInt(i_.css('bottom'), 10);
          var i_winW = $(window).width();

          var i_he = wH - (i_top + i_bottom);
          var i_wi = wW - (i_left + i_right);

          i_.css({
            'height': i_he,
            'width' : i_wi
          });

          var infoC = $('.info_content').height();
          var paragraph = infoC - ($('.huge_line').height() + $('.title_heading').height() + 15 + $('.location_heading').height() + 30);
          $('p.simplebar').height(paragraph);

          if($('.info_content_images').find('.simplebar-scroll-content')) {
            i_.simplebar('recalculate');
          } else {
            i_.simplebar({ autoHide: false });
          }
        }


      });

      function adjustMenu(number) {
        var menuItemHeight = $('.menu ul li:first').height() + 10;
        var menuItemHeight2 = $('.menu2 ul li:first').height() + 10;
        var wH = $(window).height();
        if($(window).width() <= 900) {
          var top = 50 + 126;
        } else {
          var top = wH / 2.25;
        }
        var adjustTop = top - (menuItemHeight * number);
        var adjustTop2 = top + (menuItemHeight2 * (number+3));
        var adjustTop = top - (menuItemHeight * number);
        var adjustTop2 = top + (menuItemHeight2 * (number+3));

        $('.menu').css('top', adjustTop);
        $('.menu_mobile').css('top', adjustTop);
        $('.menu2').css('top', -(adjustTop2));
        var menuHeight = $('.menu').height();
      }*/
    }
  },)



  return (
      <div ref={ref => (mount = ref)}>
        <canvas ref={ref => (canv = ref)}></canvas>
      </div>
  )
      ;
};
