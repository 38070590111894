import React from "react";
import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";

const ControlToolbar = (props) => {
    return (
        <div>
        <BlockStyleControls
        editorState={props.editorState}
        onToggle={props.onBlockToggle}
    />
    <span className={"RichEditor-toolBarBreak"}>|</span>
    <InlineStyleControls
        editorState={props.editorState}
        onToggle={props.onInlineToggle}
    /><span className={"RichEditor-toolBarBreak"}>|</span>
            {props.children}
        </div>
    )
}
export default ControlToolbar