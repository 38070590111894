import React from 'react';
import Editor, {composeDecorators}from 'draft-js-plugins-editor';
import createHashtagPlugin from 'draft-js-hashtag-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createDragNDropUploadPlugin from '@mikeljames/draft-js-drag-n-drop-upload-plugin';
import { RichUtils, getDefaultKeyBinding } from 'draft-js';
import ControlToolbar from "./ControlToolbar";
import {getBlockStyle, styleMap} from "./config";
import './RichEditor.css'
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-alignment-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';
import TextStyles from './TextStyles.css'



const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();
const emojiPlugin = createEmojiPlugin({useNativeArt:true});
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
const decorator = composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
);
const imagePlugin = createImagePlugin({ decorator });

const dragNDropFileUploadPlugin = createDragNDropUploadPlugin({
    handleUpload: ()=>true,
    addImage: imagePlugin.addImage,
});



const plugins = [
    linkifyPlugin,
    hashtagPlugin,
    emojiPlugin,
    dragNDropFileUploadPlugin,
    blockDndPlugin,
    focusPlugin,
    alignmentPlugin,
    resizeablePlugin,
    imagePlugin,
];

export default class RichEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editorState: this.props.editorState}
                //EditorState.createEmpty()};

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.props.onChange(editorState)
            return this.setState({editorState});
        }

        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    render() {
        const {editorState} =  this.props

        /*const onChangeHandler =  () => {
                this.onChange = (editorState) => {
                    if (this.props.onChange) {this.props.onChange(editorState)}
                    this.state.onChange(editorState);
                }
            }
*/

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        let contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return this.props.readOnly ? (
            <div className="RichEditor-root">
                <ControlToolbar editorState={editorState} onBlockToggle={this.toggleBlockType} onInlineToggle={this.toggleInlineStyle}><EmojiSelect />{this.props.toolBar}</ControlToolbar>
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        placeholder="Tell a story..."
                        ref="editor"
                        spellCheck={true}
                        plugins={plugins}
                        readOnly={false}
                        handlePastedFiles={(files)=>{console.log(files)}}
                    />
                    <AlignmentTool />
                    <EmojiSuggestions style={{"margin-bottom": "20px"}}/>

                </div>

            </div>) : (<div className="RichEditor-root"><div className={className} onClick={this.focus}>
            <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                handleKeyCommand={this.handleKeyCommand}
                keyBindingFn={this.mapKeyToEditorCommand}
                onChange={this.onChange}
                placeholder="Tell a story..."
                ref="editor"
                spellCheck={true}
                plugins={plugins}
                readOnly={true}
                handlePastedFiles={(files)=>{console.log(files)}}
            />
            <EmojiSuggestions style={{"margin-bottom": "20px"}}/>

        </div></div>)
        ;
    }
}
