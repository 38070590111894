export { default as Time } from "./time"
export { default as Initials } from "./Initials"

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}

export const pushToArray = (arr, obj) => {
    const index = arr.findIndex((e) => e.id === obj.id);

    if (index === -1) {
        arr.push(obj);
    } else {
        arr[index] = obj;
    }
    return arr
}

export const sortArrayOfObjects = (array,ordinalKey) => {
    const arr = array.concat()
    arr.sort((a,b) => {
        if (a[ordinalKey] < b[ordinalKey]) {
            return -1;
        }
        if (a[ordinalKey] > b[ordinalKey]) {
            return 1;
        }
        return 0;
    })
    return arr
}

export const makeid = (length, inCharacters) => {
    let result = '';
    const characters = inCharacters || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}