export default {
    getInitals : (name) => {
        var parts = name.split(' ')
        var initials = ''
        for (var i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && parts[i] !== '') {
                initials += parts[i][0]
            }
        }
        return initials
    },
    initalsColour:  (initials) => {
        var x = (initials.toLowerCase().charCodeAt(0))-96
        var y = (initials.toLowerCase().charCodeAt(1))-96
        var a = [1,4,7,10,13,16,19,22,25,28]
        var b = [2,5,8,11,14,17,20,23,26]
        var c = [3,6,9,12,15,18,21,24,27]
        if (x > y) {
            if (a.includes(x)){

                //calcualates the other value z and offsets based off xy val
                var z = ""
                if (x > 12) {
                    z = (x+y)/2/28*127.5
                }
                else {
                    z = 127.5-((x+y)/2/28*127.5)
                }


                //calculates and returns a RGB Colour

                return "rgb("+ z +","+ charToColVal(x) +","+ charToColVal(y) +")"
            }
            else if (b.includes(x)) {

                //calcualates the other value z and offsets based off xy val
                var z = ""
                if (x > 12) {
                    z = (x+y)/2/28*127.5
                }
                else {
                    z = 127.5-((x+y)/2/28*127.5)
                }


                //calculates and returns a RGB Colour

                return "rgb("+ charToColVal(x) +","+ charToColVal(y) +","+ z +")"
            }
            else {//c.includes(x)  or any error

                //calcualates the other value z and offsets based off xy val
                var z = ""
                if (x > 12) {
                    z = (x+y)/2/28*127.5
                }
                else {
                    z = 127.5-((x+y)/2/28*127.5)
                }


                //calculates and returns a RGB Colour

                return "rgb("+ charToColVal(y) +","+ z +","+ charToColVal(x) +")"
            }
        }
        else {
            if (a.includes(y)){

                //calcualates the other value z and offsets based off xy val
                var z = ""
                if (y > 12) {
                    z = (x+y)/2/28*127.5
                }
                else {
                    z = 127.5-((x+y)/2/28*127.5)
                }


                //calculates and returns a RGB Colour

                return "rgb("+ z +","+ charToColVal(y) +","+ charToColVal(x) +")"
            }
            else if (b.includes(y)) {

                //calcualates the other value z and offsets based off xy val
                var z = ""
                if (y > 12) {
                    z = (x+y)/2/28*127.5
                }
                else {
                    z = 127.5-((x+y)/2/28*127.5)
                }


                //calculates and returns a RGB Colour

                return "rgb("+ charToColVal(y) +","+ charToColVal(x) +","+ z +")"
            }
            else {//c.includes(y)  or any error

                //calcualates the other value z and offsets based off xy val
                var z = ""
                if (y > 12) {
                    z = (x+y)/2/28*127.5
                }
                else {
                    z = 127.5-((x+y)/2/28*127.5)
                }


                //calculates and returns a RGB Colour

                return "rgb("+ charToColVal(x) +","+ z +","+ charToColVal(y) +")"
            }
        }
        function charToColVal(character) {
            return character/28*255
        }
    }
}