import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {updateObject} from '../../../../../utility'

export const actionTypes = {
  Login: "[WDashboard Authentication] Action",
  Logout: "[WDashboard Logout Authentication] Action",
  UpdateToken: "[WDashboard Auth Token] Action"
};


const initialAuthState = {
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "wDashboard-auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const payload = action.payload;

        return updateObject(state,
            {
              authToken : payload.token,
            }
        )
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UpdateToken:
        return updateObject(state,
            {
              ...state,
              authToken : action.token,
            }
        )

      default:
        return state;
    }
  }
);

export const actions = {
  login: (token) => ({ type: actionTypes.Login, payload: { token } }),
  logout: () => ({ type: actionTypes.Logout }),
  updateToken: token => ({ type: actionTypes.UpdateToken, token: token })
};