import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, select, takeLatest} from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import {updateObject} from '../../../../utility'

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  UpdateToken: "[Update Auth Token] Action"
};


const initialAuthState = {
  user: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v713-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const payload = action.payload;

        return updateObject(state,
            {
              authToken : payload.token,
              user: {
                username: payload.user,
                id: payload.id,
                fullname: payload.name,
                firstname: payload.name.split(" ")[0],
                email: payload.emailAddress,
              }
            }
        )
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const user = updateObject(state.user, action.payload.user)
        return updateObject(state, {user: user})
      }

      case actionTypes.UpdateToken:
        return updateObject(state,
            {
              ...state,
              authToken : action.token,
            }
        )

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (auth) => ({ type: actionTypes.Login, payload: {
      token : auth.token,
      user: auth.user.userName,
      id: auth.user.id,
      name: auth.user.name,
      emailAddress: auth.user.emailAddress } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  updateToken: token => ({ type: actionTypes.UpdateToken, token: token })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {

    let {data : userImage} = yield getUserByToken(yield select());

    let user = {
      roles: [1], // Administrator
      pic: userImage,
      occupation: "CEO",
      companyName: "Crystal Productions",
      phone: "02 8011 0420",
      address: {
        addressLine: "Unit 4/45a Eastern Creek Drive",
        city: "Eastern Creek",
        state: "NSW",
        postCode: "2766"
      },
      socialNetworks: {
        linkedIn: "https://linkedin.com/admin",
        facebook: "https://facebook.com/admin",
        twitter: "https://twitter.com/admin",
        instagram: "https://instagram.com/admin"
      }
    }

    yield put(actions.fulfillUser(user));
  });
}