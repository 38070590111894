import axios from "axios";

export const LOGIN_URL = "https://cp.flexrentalsolutions.com/f5/api/auth";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const FLEX_SOURCE = "FLEX5_EXTJS_CLIENT_SOURCE"
export const FLEX_AUTH_HEADER = {} //{headers: {'X-Auth-Token': store-NOTUSED.getState().auth-NOTUSED.token}}

export const ME_URL = "https://cp.flexrentalsolutions.com/f5/api/contact/imageUrl/";

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password, "source": FLEX_SOURCE },FLEX_AUTH_HEADER);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(state) {
  const userId = state.auth.user.id
  const URL = ME_URL + userId
  // Authorization head should be fulfilled in interceptor.
  return axios.get(URL).then()
}