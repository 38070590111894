import React from "react";
import {useLocation} from "react-router";
import {checkIsActive, toAbsoluteUrl} from "../../../../../../_metronic/_helpers";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import MenuConfig from "../MenuConfig";
import MenuSection from "../../../../../../_metronic/layout/aside/MenuSection";
import MenuItemSeparator from "../../../../../../_metronic/layout/aside/MenuItemSeparator";
import AsideItem from "./AsideItem";
import {useSelector, useDispatch} from "react-redux";
import {actions as layoutActions} from "../../../_redux/layoutRedux"
import axios from "axios";

let BaseURL = process.env.REACT_APP_BASEURL

export default ({ layoutProps }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };
    React.useEffect(()=> {
        axios.get(
            BaseURL + '/ui/menu',{params:{}})
            .then(response => {
                console.log(response)
                const {Menus}  = response.data
                dispatch(layoutActions.setMenu(Menus.sort((a,b) =>{
                        return a.ordinal > b.ordinal
                }
                    )))
            })
    },[])
    const Menu = useSelector(state => state.layout.menu)
    return Menu.map((child, index) => {
        return(
            <React.Fragment key={`menuList${index}`}>
                {child.section && <MenuSection item={child} />}
                {child.separator && <MenuItemSeparator item={child} />}
                {child.title && (
                    <AsideItem
                        item={child}
                        layoutProps
                    />
                )}
            </React.Fragment>
        )
    })
}